import React, { useEffect, useRef } from 'react';

import Hls from 'hls.js';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import * as styles from './VideoPlayer.module.scss';

/**
 * Return a HLS videoplayer using asset data from Sanity
 * @param {string} className Class with styles given from the parent
 * @param {object} asset Asset data from Sanity
 * @param {bool} isBackgroundVideo Specifies if the video should display controls or autoplay inline
 *
 */

const VideoPLayer = ({ className, asset, isBackgroundVideo }) => {
  const videoRef = useRef(null);

  const src = `https://stream.mux.com/${asset.playbackId}.m3u8`;
  useEffect(() => {
    let hls;
    if (videoRef.current) {
      const video = videoRef.current;
      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = src;
      } else if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }
    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoRef, asset.playbackId, src]);

  return (
    <video
      ref={videoRef}
      className={classNames(styles.video, className)}
      autoPlay={isBackgroundVideo}
      loop={isBackgroundVideo}
      muted={isBackgroundVideo}
      playsInline={isBackgroundVideo}
      controls={!isBackgroundVideo}
      poster={`https://image.mux.com/${asset.playbackId}/thumbnail.jpg`}
    />
  );
};

VideoPLayer.propTypes = {
  asset: PropTypes.object.isRequired,
  isBackgroundVideo: PropTypes.bool,
  className: PropTypes.string,
};

VideoPLayer.defaultProps = {
  asset: null,
  isBackgroundVideo: true,
  className: null,
};

export default VideoPLayer;
